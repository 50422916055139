@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
/* USER STYLES */

.opacity-95 {
  opacity: .95;
}

body {

  /* font-family: 'Roboto', sans-serif; */

}

.transition {
  transition: all .2s;
}

body i {
  font-family: 'FontAwesome'!important;
  font-style: normal!important;
  

}

.icon {
  background: -webkit-linear-gradient(rgb(45, 51, 226), rgb(19, 0, 224));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button-hero {
  background: -webkit-linear-gradient(right, rgb(45, 117, 226), rgb(0, 60, 224));
}

.bg-hero {
  /* background: -webkit-linear-gradient(right, rgb(0, 71, 179), rgb(0, 38, 143)); */

  background: -webkit-linear-gradient(left, #ffffff, rgb(236, 236, 236));
}

.slick-prev:before, .slick-next:before {
  color: black!important
}

.slider {
  width: 384px!important;
  height: 683px!important;
  /* overflow: hidden; */
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%)
}

.bg-balboa {
  background-image: url("https://www.balboapediatrics.com/storage/app/media/waiting-room-1024x685.jpg");
  background: cover
}
